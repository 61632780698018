<template>
  <c-box
    margin-top="24px"
  >
    <MealPlanMenuRecommendationContainer 
      :is-edited="isEdited"
      :is-loading-data="isLoadingData"
      :preview="preview"
      :calendars="calendars"
      :is-program-consultation-basic="isProgramConsultationBasic"
      :tabs="_tabs"
    />
  </c-box>
</template>

<script>
import { reqNutriMealPlan } from '@/requests/dietela-api/nutritionist/meal-plans'
import MealPlanMenuRecommendationContainer from '@/components/meal-plan/menu-recommendation/container.vue'
import { reqNutritionist_mealPlans_preview } from '@/requests/dietela-api/nutritionist/other'
import { mapMutations } from 'vuex'
import mixinsTabsMealPlan from '@/mixins/mixins-tabs-meal-plan'
import { reqMenuMealPlan_TriggerForBE_1 } from '@/requests/dietela-api/other'

export default {
  components: {
    MealPlanMenuRecommendationContainer,
  },
  mixins: [mixinsTabsMealPlan],
  props: {
    isEdited: {
      type: Boolean,
      default: true,
    },
    isProgramConsultationBasic: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoadingData: true,
      preview: null,
      calendars: [],
    }
  },
  computed: {
    day() {
      return this.$route.query.day ?? 1
    },
    month() {
      return this.$route.params.month ?? 1
    },
  },
  watch: {
    $route: {
      immediate: true,
      async handler() {
        await this.init()
        await this.mealPlanPreview()
      },
    },
  },
  methods: {
    ...mapMutations({
      setMealPlanPreview: 'mealPlan/setMealPlanPreview',
    }),
    async init() {
      this.isLoadingData = true
      // Trigger for BE 1
      await reqMenuMealPlan_TriggerForBE_1(this.$store.getters.axios, {
        clientId: this.$route.params.clientId,
        programId: this.$route.params.programId,
        month: this.$route.params.month,
      })

      if (this.calendars.length === 0) {
        const res = await reqNutriMealPlan.getMenuRecommendationCalendar(this.$store.getters.axios,
          {
            clientId: this.$route.params.clientId,
            programId: this.$route.params.programId,
          },
        )
        this.calendars = res?.data?.data
      }
      
      const selectedDays = this.calendars.find((it) => it?.days == this.day && it?.month == this.month)
      if (selectedDays && selectedDays?.id) {
        const preview = await reqNutriMealPlan.getMenuRecommendationPreview(this.$store.getters.axios, {
          id: selectedDays?.id,
        })
        this.preview = (preview?.data?.data && Object.keys(preview?.data?.data).length > 0) ? preview?.data?.data : null
      } else {
        this.preview = null
      }

      this.isLoadingData = false
    },
    async mealPlanPreview() {
      const res = await reqNutritionist_mealPlans_preview(this.$store.getters.axios,
        {
          clientId: this.$route.params.clientId,
          programId: this.$route.params.programId,
          month: this.$route.params.month,
        },
      )
      this.setMealPlanPreview(res?.data?.data)
    },
  },
}
</script>
